import React, { Component } from "react";
import Skeleton from "react-loading-skeleton";
import { connect } from "react-redux";
import CronJobSelectionMenu from "views/common/fragments/CronJobSelectionMenu";
import CustomInput from "views/common/fragments/formfields/CustomInput";
import { employeeActions } from "_actions";
import { scheduleActions, taskActions } from "_actions";
import { projectConstants } from "_constants";
import { commonUtils, store, validationUtils } from "_helpers";
import dateUtils from "_helpers/date.utils";
import SidebarFooter from "./SidebarFooter";
import classNames from "classnames/bind";
import { accessUtils } from "_helpers";

class CreateReportConfigSidebar extends Component {
  constructor() {
    super();
    this.state = {
      formErrors: {},
      showCreateWindow: false,
      scheduleRequest: null,
      editMode: false,
      permission: accessUtils.getReportAccessControl()
    }
  }

  toggeleCreateWindow = (showCreateWindow) => {
    this.setState({
      showCreateWindow: showCreateWindow
    })
    if (showCreateWindow === false) {
      this.toggeleEditMote(false);
    }
  }

  toggeleEditMote = (showCreateWindow) => {
    this.setState({
      editMode: showCreateWindow
    })
  }

  closeReportSchedulerWindow = () => {
    store.dispatch({ type: projectConstants.CLOSE_REPORTCONFIG_WINDOW });
  }

  handleGetAllReportScheulers = () => {
    let selectedUserTeams = []
    this.props.userTeams && this.props.userTeams.map(userTeam => userTeam.isChecked && selectedUserTeams.push(userTeam.id));
    store.dispatch(this.props.getReportSchedules({ itemId: this.props.itemId, userTeamIds: (this.state.permission.reportScheduleAllTeamAccessEnabled ? null : selectedUserTeams), type: this.props.type, scheduleType: this.props.scheduleType }));
  }

  updatescheduleRequest = (scheduleRequest) => {
    if (scheduleRequest !== null) {
      this.toggeleCreateWindow(true);
      this.setState({
        scheduleRequest: scheduleRequest,
        editMode: true
      })
    } else {
      this.setState({
        scheduleRequest: null,
      })
    }
  }


  render() {
    return (
      <div id="createreportconfigsidebar" className={`right_sidebar create_reportconfig-right_sidebar   ${this.props.show ? "open" : ""}`}>
        <div className="h-100vh">

          <div className="card-header py-0 border-bottom">
            <h3 className="card-title">{this.props.editMode ? '' : ''} Report Notification Configuration</h3>
            <div className="card-options">

              <button onClick={() => this.toggeleCreateWindow(!this.state.showCreateWindow)} className="link create-link pt-0 xs-hide pr-3 pl-3 fsize11">
                {!this.state.showCreateWindow &&
                  <> <i className="fe fe-plus-circle mr-1"></i>Create Notification</>
                }
                {this.state.showCreateWindow &&
                  <>  <i className="fe fe-plus-circle mr-1 "></i>Cancel</>
                }
              </button>

              <button onClick={this.closeReportSchedulerWindow} className="link p-2 create-project-close-link float-right"><i className="fa fa-close" /></button>
            </div>
          </div>
          <div className="card-body">
            {this.props.show && this.state.showCreateWindow &&
              <CreateReportConfigSidebarSection
                handleSubmit={this.props.handleSubmit}
                editMode={this.state.editMode}
                scheduleRequest={this.state.scheduleRequest}
                selectedReportConfigInfo={this.props.selectedReportConfigInfo}
                currentUser={this.props.currentUser}
                fieldConfig={this.props.fieldConfig}
                createConfig={this.props.createConfig}
                allEmployees={this.props.allEmployees}
                employeeAppConfig={this.props.employeeAppConfig}
                allRoles={this.props.allRoles}
                allUserTeams={this.props.allUserTeams}
                isProcessing={this.props.creating || this.props.updating}
                reportOptions={this.props.reportOptions}
                userTeams={this.props.userTeams}
                toggeleEditMote={this.toggeleEditMote}
                userTeamId={this.props.userTeamId}
                scheduleType={this.props.scheduleType}
                createSchedule={this.props.createSchedule}
                updateSchedule={this.props.updateSchedule}
                closeReportSchedulerWindow={this.closeReportSchedulerWindow}
                toggeleCreateWindow={this.toggeleCreateWindow}
                updatescheduleRequest={this.updatescheduleRequest}
              />
            }
          </div>
          {this.props.show &&
            <ReportConfigTable
              items={this.props.items}
              editMode={this.state.editMode || this.state.showCreateWindow}
              getAllReportScheulers={this.handleGetAllReportScheulers}
              enableSchedule={this.props.enableSchedule}
              removeSchedule={this.props.removeSchedule}
              updatescheduleRequest={this.updatescheduleRequest}
              allUserTeams={this.props.allUserTeams}
              allEmployees={this.props.allEmployees}
            />
          }
        </div>
      </div>
    );
  }
}

class ReportConfigTable extends Component {

  componentDidMount() {
    this.props.getAllReportScheulers()
  }

  render() {
    return (
      <div>
        <div className="table-responsive p-2">
          {this.props.isLoading &&
            <table className="table table-hover table-vcenter table-striped">
              <tbody>
                <tr>
                  <td colSpan="12">
                    <Skeleton count={7} height={40} />
                  </td>
                </tr>
              </tbody>
            </table>
          }
          {!this.props.isLoading && this.props.allUserTeams && this.props.items && this.props.items.map((item, index) =>
            <ReportConfigTableItem
              key={item.id}
              item={item}
              editMode={this.props.editMode}
              enableSchedule={this.props.enableSchedule}
              removeSchedule={this.props.removeSchedule}
              updatescheduleRequest={this.props.updatescheduleRequest}
              allUserTeams={this.props.allUserTeams}
              allEmployees={this.props.allEmployees}
            />
          )}
        </div>
      </div>
    );
  }
}


class ReportConfigTableItem extends Component {
  constructor() {
    super();
    this.state = {
      confrimDelete: false,
      isActive: false,
      editEnabled: false
    }
  }
  toggeleShowDetails = (isActive) => {
    this.setState({
      isActive: isActive
    })
  }

  handleEnable = (isEnabled) => {
    store.dispatch(this.props.enableSchedule({ itemId: this.props.item.id, isEnabled }));
  }

  handleEdit = (id, item) => {
    this.props.updatescheduleRequest(item);
  }

  getUserTeamNames = (userTeamIds) => {
    return this.props.allUserTeams && this.props.allUserTeams.filter(userTeam => userTeamIds.includes(userTeam.id)).map(userTeam => userTeam.name);
  }

  geEmployeeNameName = (employeeId) => {
    return this.props.allEmployees && this.props.allEmployees.filter(employee => employeeId === employee.id).map(employee => employee.name);
  }


  handleRemove = (isConfrimDelete) => {

    if (isConfrimDelete) {
      store.dispatch(this.props.removeSchedule({ itemId: this.props.item.id }));
    } else {
      this.setState({
        confrimDelete: true
      })
    }
  }
  render() {
    return (
      <div className={`taskActivityContainer ${this.state.isActive ? "active" : ""}`} >

        <div className="d-flex justify-content-between align-items-center mt-0 py-0 mb-1 px-2 shadow-sm bg-white rounded">
          <div onClick={() => this.toggeleShowDetails(!this.state.isActive)} >
            <i className={classNames("fa-lg",
              { "fa fa-check-circle colorgreen": this.props.item.enabled === true },
              { "fa fa-minus-circle colorred": this.props.item.enabled === false }
            )}></i>
            <span className="mx-1 fweight600 fsize13 colorblue">{this.props.item.id}</span>
            <span title={this.props.item.name} className={`fsize14 mx-1 d-inline-block text-truncate mt-0 align-middle w-300`}>
              {this.props.item.name}
            </span>

          </div>
          <div>
            <span className="float-right">

              <span className="mt-1 ml-4">
                <span className="fsize14 mx-1">{this.props.item.cronString || ''}</span>
                <span>
                  <label className=" custom-switch custom-switch-sm m-0">
                    <input type="checkbox" onChange={() => { }}
                      onClick={e => this.handleEnable(!this.props.item.enabled)}
                      checked={this.props.item.enabled ? true : false}
                      className="custom-switch-input" />
                    <span className="custom-switch-indicator" />
                  </label>
                </span>
                {this.props.editMode === false &&
                  <button type="button"
                    className="link pl-2"
                    onClick={(e) => this.handleEdit(this.props.item.id, this.props.item)}
                    title="Edit"><i className="fa fa-edit" /></button>
                }
                <button type="button"
                  onClick={(e) => this.handleRemove(this.state.confrimDelete)}
                  className="link pl-2" title="Remove">
                  {this.state.confrimDelete === true &&
                    <i className="fe fe-check colorred blink_text"></i>
                  }
                  {this.state.confrimDelete === false &&
                    <i className="fe fe-x colorred"></i>
                  }
                </button>
              </span>
            </span>
          </div>
        </div>
        {this.state.isActive &&
          <div className="card">
            <div className="card-status card-status-left bg-blue"></div>
            <div className="card-header py-0 pr-2">
              <span className="fsize12">{this.props.item.name}</span>
            </div>
            <div className="card-body py-0 ml-2 pl-3">
              <div className="overlaySection">
                <div className="row pt-1 text-left">
                  <div className="form-group col-3 mb-1">
                    <label className="form-label mb-0 text-muted">Created By</label>
                    <span className="fsize12 fweight600 menu-text-black">
                      {(this.props.item && this.props.item.createdBy && this.geEmployeeNameName(this.props.item.createdBy)) || 'NA'}
                    </span>
                  </div>
                  <div className="form-group col-3 mb-1">
                    <label className="form-label mb-0 text-muted">Created Date</label>
                    <span className="fsize12 fweight600 menu-text-black">
                      {(this.props.item && dateUtils.convertDateTimeFormat(this.props.item.createdDate)) || 'NA'}
                    </span>
                  </div>

                  <div className="form-group col-3 mb-1">
                    <label className="form-label mb-0 text-muted">Report Type</label>
                    <span className="fsize12 fweight600 menu-text-black">
                      {(this.props.item.recurringInfo && this.props.item.recurringInfo.reportType) || 'NA'}
                    </span>
                  </div>

                  <div className="form-group col-3 mb-1">
                    <label className="form-label mb-0 text-muted">End Date</label>
                    <span className="fsize12 fweight600 menu-text-black">
                      {(this.props.item.recurringInfo && dateUtils.convertDateTimeFormat(this.props.item.recurringInfo.endDate)) || 'NA'}
                    </span>
                  </div>

                  <div className="form-group col-3 mb-1">
                    <label className="form-label mb-0 text-muted">Schedule</label>
                    <span className="fsize12 fweight600 menu-text-black">
                      {(this.props.item && this.props.item.cronString) || 'NA'}
                    </span>
                  </div>

                  <div className="form-group col-3 mb-1">
                    <label className="form-label mb-0 text-muted">Next Run</label>
                    <span className="fsize12 fweight600 menu-text-black">
                      {(this.props.item && dateUtils.convertDateTimeFormat(this.props.item.nextRun)) || 'NA'}
                    </span>
                  </div>

                  <div className="form-group col-3 mb-1">
                    <label className="form-label mb-0 text-muted">Last Run</label>
                    <span className="fsize12 fweight600 menu-text-black">
                      {(this.props.item && dateUtils.convertDateTimeFormat(this.props.item.lastRun)) || 'NA'}

                    </span>
                  </div>

                  <div className="form-group col-3 mb-1">
                    <label className="form-label mb-0 text-muted">Notify Defaulters</label>
                    <span className="fsize12 fweight600 menu-text-black">
                      {(this.props.item.recurringInfo && this.props.item.recurringInfo.notifyDefaulters === true) ? "YES" : "NO"}
                      {this.props.item.recurringInfo.notifyDefaulters === true ? (" ( " + (this.props.item.recurringInfo.lowerLimit || 'NA') + " - " + (this.props.item.recurringInfo.upperLimit || 'NA') + " )") : ""}
                    </span>
                  </div>

                  <div className="form-group col-12 mb-1">
                    <label className="form-label mb-0 text-muted">Subject</label>
                    <span className="fsize12 fweight600 menu-text-black">
                      {(this.props.item && this.props.item.subject) || 'NA'}
                    </span>
                  </div>

                  <div className="form-group col-3 mb-1">
                    <label className="form-label mb-0 text-muted">Teams</label>
                    <span className="fsize12 fweight600 menu-text-black">
                      {(this.props.item.recurringInfo && this.props.item.recurringInfo.userTeamIds) ? this.getUserTeamNames(this.props.item.recurringInfo.userTeamIds).map(value => <p className="m-0">{value}</p>) : "NA"}
                    </span>
                  </div>

                  <div className="form-group col-3 mb-1">
                    <label className="form-label mb-0 text-muted">To List</label>
                    <span className="fsize12 fweight600 menu-text-black">
                      {(this.props.item && this.props.item.toList) ? this.props.item.toList.map(value => <p className="m-0">{value}</p>) : "NA"}
                    </span>
                  </div>

                  <div className="form-group col-3 mb-1">
                    <label className="form-label mb-0 text-muted">CC List</label>
                    <span className="fsize12 fweight600 menu-text-black">
                      {(this.props.item && this.props.item.ccList) ? this.props.item.ccList.map(value => <p className="m-0">{value}</p>) : "NA"}
                    </span>
                  </div>

                  <div className="form-group col-3 mb-1">
                    <label className="form-label mb-0 text-muted">Exclude List</label>
                    <span className="fsize12 fweight600 menu-text-black">
                      {(this.props.item.recurringInfo && this.props.item.recurringInfo.excludeMails) ? this.props.item.recurringInfo.excludeMails.map(value => <p className="m-0">{value}</p>) : "NA"}
                    </span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        }
      </div>
    )
  }
}

class CreateReportConfigSidebarSection extends Component {

  constructor() {
    super();
    this.state = {
      formErrors: {},
      hasFormError: false,
      selectedReportTypeOption: null
    }
  }

  componentDidMount() {
    this.initialize()
  }
  onResetFormError = () => {
    this.setState({
      hasFormError: false
    })
  }
  initialize = () => {
    store.dispatch(employeeActions.getAllEmployees())
    this.setState({
      showCroDropdownMenu: false,
      selectedReportTypeOption: this.props.scheduleRequest && this.props.scheduleRequest.recurringInfo ? this.props.reportOptions.find(report => report.name === this.props.scheduleRequest.recurringInfo.reportType) : "",
      scheduleRequest: this.props.scheduleRequest ? { ...this.props.scheduleRequest } : {
        "userTeamId": this.props.userTeamId,
        "scheduleType": this.props.scheduleType,
        "cronValue": "0 05 0 ? * MON-FRI",
        "cronString": "At 12:05 AM, Monday Through Friday",
        "recurringInfo": {
          notifyDefaulters: false,
          userTeamIds: [],
          reportType: undefined
        }
      }
    })
    this.props.updatescheduleRequest(null)
  }
  onCreateAnotherEnabled = (value) => {
    this.setState({
      createAnotherEnabled: value
    })
  }

  onFormError = (fieldName, hasError) => {
    this.setState(prevState => ({
      formErrors: {
        ...prevState.formErrors,
        [fieldName]: hasError
      }
    }))
  }

  onChange = (fieldName, value) => {
    this.onResetFormError()
    this.setState(prevState => ({
      scheduleRequest: {
        ...prevState.scheduleRequest,
        [fieldName]: value
      }
    }))
  }

  onRecurringInfoReportChange = (fieldName, reportTypeOption) => {
    this.setState(prevState => ({
      selectedReportTypeOption: reportTypeOption
    }), () => {
      var notifyDefaulters = true;
      if (reportTypeOption.notifyDefaultersEnabled === undefined || reportTypeOption.notifyDefaultersEnabled === false) {
        notifyDefaulters = false;
      }
      var lowerLimit = "0";
      var upperLimit = "9999";
      if (reportTypeOption.lowerLimit) {
        lowerLimit = reportTypeOption.lowerLimit;
      }
      if (reportTypeOption.upperLimit) {
        upperLimit = reportTypeOption.upperLimit;
      }
      this.setState(prevState => ({
        scheduleRequest: {
          ...prevState.scheduleRequest,
          recurringInfo: {
            ...prevState.scheduleRequest.recurringInfo,
            [fieldName]: reportTypeOption.name,
            notifyDefaulters: notifyDefaulters,
            lowerLimit: lowerLimit,
            upperLimit: upperLimit
          }
        }
      }))
    })
  }

  onRecurringInfoChange = (fieldName, value) => {
    this.setState(prevState => ({
      scheduleRequest: {
        ...prevState.scheduleRequest,
        recurringInfo: {
          ...prevState.scheduleRequest.recurringInfo,
          [fieldName]: value
        }
      }
    }))
  }

  onCronValueChange = (cronValue, cronString) => {
    this.setState(prevState => ({
      scheduleRequest: {
        ...prevState.scheduleRequest,
        "cronString": cronString,
        "cronValue": cronValue.substr(0, cronValue.length - 1)
      }
    }), () => {

    })
    this.toggeleCronMenu(false);
  }

  toggeleCronMenu = (isShow) => {
    this.setState(prevState => ({
      showCroDropdownMenu: isShow
    }))
  }

  handleSubmit = (createAnotherEnabled) => {
    let isValid = validationUtils.isValidRequest(this.props.fieldConfig, this.state.scheduleRequest, this.state.formErrors);
    //console.log("Validating form  request, isValid - " + isValid);
    //console.log(this.state.scheduleRequest);
    //console.log(this.state.formErrors)
    isValid = (this.state.scheduleRequest.recurringInfo && this.state.scheduleRequest.recurringInfo.reportType && this.state.scheduleRequest.recurringInfo.userTeamIds) && isValid;
    if (isValid) {
      if (this.props.editMode === true) {
        store.dispatch(this.props.updateSchedule(this.state.scheduleRequest, this.props.scheduleType));
        this.props.toggeleCreateWindow(false);
        this.props.toggeleEditMote(false)
      } else {
        store.dispatch(this.props.createSchedule(this.state.scheduleRequest, this.props.scheduleType));
      }
      if (createAnotherEnabled) {
        this.initialize();
      } else {
        this.props.toggeleCreateWindow(false);
      }
    } else {
      this.setState({
        hasFormError: true
      })
    }
  }

  render() {
    return (
      <>
        {this.state.scheduleRequest &&
          <>
            <div className="card-body pl-3 pr-3">
              <div className="row">
                <div className="card pb-0 mb-0">
                  <div className="row">
                    <div className="col-12 row">
                      <div className="col-4">
                        <CustomInput inputtype='text'
                          label="Name"
                          name="name"
                          fieldConfig={this.props.fieldConfig}
                          mappingObject={this.state.scheduleRequest}
                          onChange={this.onChange}
                          onFormError={this.onFormError}
                          placeholder="Config Name..."
                          datatype='alpnumspl' />

                      </div>
                      <div className="col-4">
                        {this.state.scheduleRequest.recurringInfo &&
                          <CustomInput
                            type='select'
                            label="Report Type"
                            name="reportType"
                            fieldConfig={this.props.fieldConfig}
                            mappingObject={{ ...this.state.scheduleRequest.recurringInfo, "reportType": this.state.selectedReportTypeOption }}
                            onChange={this.onRecurringInfoReportChange}
                            placeholder="Report..."
                            options={this.props.reportOptions && this.props.reportOptions.filter(report => report.emailEnabled === true).map(item => { return ({ value: item.name, label: item.label, ...item }) })}
                            onFormError={this.onFormError}
                            getSelectOptionEnabled={true}

                          />
                        }
                      </div>
                      <div className="col-4">
                        <CustomInput
                          type='dateselect'
                          label="End Date"
                          name="endDate"
                          fieldConfig={this.props.fieldConfig}
                          mappingObject={this.state.scheduleRequest}
                          onChange={this.onChange}
                          time={false}
                          onFormError={this.onFormError}

                          minDate={new Date()}
                          currentDate={new Date()}
                        />
                      </div>
                    </div>
                    <div className="col-12 row">
                      <div className="col-6 row">
                        <div className="col-12">
                          <label className="form-label text-muted">Schedule<span className="form-required">*</span></label>
                          <i className="fa fa-recycle pr-1 colorblue" onClick={() => this.toggeleCronMenu(!this.state.showCroDropdownMenu)} />
                          <CronJobSelectionMenu
                            showDropDown={this.state.showCroDropdownMenu}
                            closeDropDwonMenu={this.toggeleCronMenu}
                            onChange={this.onCronValueChange}
                            cronValue={this.state.scheduleRequest.cronValue}
                          />
                          <span className="fsize13 fweight600 menu-text-black mr-5" onClick={() => this.toggeleCronMenu(!this.state.showCroDropdownMenu)}>{this.state.scheduleRequest.cronString}</span>
                        </div>

                        <div className="col-4">
                          <CustomInput
                            type='select'
                            label="Duration"
                            name="reportDurationType"
                            fieldConfig={this.props.fieldConfig}
                            mappingObject={this.state.scheduleRequest.recurringInfo}
                            onChange={this.onRecurringInfoChange}
                            placeholder="When Report Start..."
                            options={[
                              { value: 'CURRENT_DAY', label: 'Current Day' },
                              { value: 'PREVIOUS_DAY', label: 'Previous Day' },
                              { value: 'CURRENT_WEEK', label: 'Current Week' },
                              { value: 'PREVIOUS_WEEK', label: 'Previous Week' },
                              { value: 'CURRENT_MONTH', label: 'Current Month' },
                              { value: 'PREVIOUS_MONTH', label: 'Previous Month' },
                              { value: 'PREVIOUS_TWO_MONTHS', label: 'Previous Two Months' },
                              { value: 'PREVIOUS_THREE_MONTHS', label: 'Previous Three Months' },
                              { value: 'PREVIOUS_SIX_MONTHS', label: 'Previous Six Months' },
                              { value: 'CURRENT_YEAR', label: 'Current Year' }
                            ]}
                            onFormError={this.onFormError}
                          />
                        </div>
                        {this.state.selectedReportTypeOption && this.state.selectedReportTypeOption.notifyDefaultersEnabled === true &&
                          < div className="col-4">
                            <CustomInput
                              type='radios'
                              label="Notify Defaulters"
                              name="notifyDefaulters"
                              fieldConfig={this.props.fieldConfig}
                              mappingObject={this.state.scheduleRequest.recurringInfo}
                              onChange={this.onRecurringInfoChange}
                              onFormError={this.onFormError}
                            />
                          </div>
                        }

                        {this.state.selectedReportTypeOption && this.state.selectedReportTypeOption.lowerLimit && this.state.scheduleRequest.recurringInfo.notifyDefaulters === true &&
                          < div className="col-2">
                            <CustomInput
                              type='text'
                              inputtype='number'
                              label="Lower Limit"
                              name="lowerLimit"
                              fieldConfig={this.props.fieldConfig}
                              mappingObject={this.state.scheduleRequest.recurringInfo}
                              onChange={this.onRecurringInfoChange}
                              onFormError={this.onFormError}
                            />
                          </div>
                        }
                        {this.state.selectedReportTypeOption && this.state.selectedReportTypeOption.upperLimit && this.state.scheduleRequest.recurringInfo.notifyDefaulters === true &&
                          < div className="col-2">
                            <CustomInput
                              type='text'
                              inputtype='number'
                              label="Upper Limit"
                              name="upperLimit"
                              fieldConfig={this.props.fieldConfig}
                              mappingObject={this.state.scheduleRequest.recurringInfo}
                              onChange={this.onRecurringInfoChange}
                              onFormError={this.onFormError}
                            />
                          </div>
                        }
                        <div className="col-12">
                          <CustomInput
                            type='select'
                            label="Restrict Only For Teams"
                            name="userTeamIds"
                            fieldConfig={this.props.fieldConfig}
                            mappingObject={this.state.scheduleRequest.recurringInfo}
                            onChange={this.onRecurringInfoChange}
                            placeholder="User Teams"
                            options={this.props.allUserTeams && commonUtils.convertObjectToSelectObject(this.props.allUserTeams)}
                            onFormError={this.onFormError}
                            multi={true}
                          />
                        </div>
                      </div>
                      <div className="col-6">
                        <div className="col-12">
                          <CustomInput
                            label="Email Subject"
                            name="subject"
                            type='textarea'
                            rows={2}
                            fieldConfig={this.props.fieldConfig}
                            mappingObject={this.state.scheduleRequest}
                            onChange={this.onChange}
                            onFormError={this.onFormError}
                            placeholder="Email Subject..."
                            datatype='alpnumspl' />

                        </div>
                      </div>

                    </div>
                    <div className="col-12 row">
                      <div className="col-4 row">
                        <EmployeeSelection allEmployees={this.props.allEmployees} selectedEmployeeEmailIds={this.state.scheduleRequest.toList || []} onAddEmployee={(emailIds) => this.onChange("toList", emailIds)} label="Add To List" />

                      </div>
                      <div className="col-4 row">

                        <EmployeeSelection allEmployees={this.props.allEmployees} selectedEmployeeEmailIds={this.state.scheduleRequest.ccList || []} onAddEmployee={(emailIds) => this.onChange("ccList", emailIds)} label="Add CC List" />

                      </div>
                      <div className="col-4 row">

                        <EmployeeSelection allEmployees={this.props.allEmployees} selectedEmployeeEmailIds={this.state.scheduleRequest.recurringInfo.excludeMails || []} onAddEmployee={(emailIds) => this.onRecurringInfoChange("excludeMails", emailIds)} label="Add Excluded List" />

                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <SidebarFooter
              handleSubmit={this.handleSubmit}
              editMode={this.props.editMode}
              closeButtonLabel={'Reset'}
              initializeRequest={this.initialize}
              hasFormError={this.state.hasFormError}
              onResetFormError={this.onResetFormError}
            />
          </>
        }
      </>
    );
  }
}

class EmployeeSelection extends Component {

  constructor() {
    super();
    this.state = {
      formErrors: {},
      selectedEmployee: {},
      hasFormError: false,
      showAddEmployeeMenu: false
    }
  }
  toggleEmailShowMenu = (isShow) => {
    this.setState({
      showAddEmployeeMenu: isShow
    })
  }

  filterSelectedEmployees = (employees) => {
    let selectedEmployeeEmailIds = this.props.selectedEmployeeEmailIds;
    return selectedEmployeeEmailIds && employees ? employees.filter(employee => !selectedEmployeeEmailIds.includes("" + employee.email)) : this.props.allEmployees;
  }

  getEmployeeName = (employeeEmail) => {
    const employee = this.props.allEmployees && this.props.allEmployees.find(employee => employee.email == employeeEmail); // single ==
    return (employee && employee.name) || 'Unknown';
  }
  onFieldValueChange = (name, selectedEmployee) => {
    this.setState({
      selectedEmployee
    })
  }

  onAddEmployee = () => {
    if (this.state.selectedEmployee) {
      const emailId = this.state.selectedEmployee.email;
      this.props.onAddEmployee([...this.props.selectedEmployeeEmailIds.filter(value => emailId != value), emailId]);
      this.setState({
        selectedEmployee: null
      })
    }
  }

  onRemoveEmployee = (valueToBeDeteleted) => {
    this.props.onAddEmployee([...this.props.selectedEmployeeEmailIds.filter(value => value != valueToBeDeteleted)]);
  }
  onFormError = () => {

  }

  render() {
    return (
      <>
        {!this.state.showAddEmployeeMenu &&
          <button className="link colorblue pl-2 fsize10" onClick={() => this.toggleEmailShowMenu(true)}>{this.props.label}</button>
        }
        {this.state.showAddEmployeeMenu &&
          <div className="col-12 row">
            <div className="col-12">
              <CustomInput
                type='select'
                label={this.props.label}
                name="employee"
                fieldConfig={this.props.fieldConfig}
                mappingObject={{ employee: this.state.selectedEmployee }}
                onChange={this.onFieldValueChange}
                placeholder="Employee..."
                options={this.props.allEmployees && commonUtils.convertObjectToSelectObject(this.filterSelectedEmployees(this.props.allEmployees))}
                onFormError={this.onFormError}
                getSelectOptionEnabled={true}
              />
              <span>
                <button onClick={() => this.onAddEmployee()} className="btn btn-primary btn-sm">Add</button>
                <button onClick={() => this.toggleEmailShowMenu(false)} className="link pl-2 fsize10">Cancel</button>
              </span>
            </div>
          </div>
        }
        {this.props.selectedEmployeeEmailIds.length > 0 &&
          <div className="col-12 row">
            <div className="table-responsive p-2">
              <table className="table table-hover table-vcenter table-striped">
                <thead>
                  <tr>
                    <th>SlNo</th>
                    <th>Employee Name</th>
                    <th></th>
                  </tr>
                </thead>
                <tbody>
                  {this.props.selectedEmployeeEmailIds.map((emailId, index) =>
                    <tr key={emailId}>
                      <td>{index + 1}</td>
                      <td>{this.getEmployeeName(emailId)}</td>
                      <td>
                        <button onClick={() => this.onRemoveEmployee(emailId)} className="link pl-2" title="Remove">
                          <i className="dropdown-icon fa fa fa-times colorred"></i>
                        </button>
                      </td>
                    </tr>
                  )
                  }
                </tbody>
              </table>
            </div>
          </div>
        }
      </>
    )
  }
}

const mapStateToProps = state => ({
  selectedTaskInfo: state.tasks.selectedTaskInfo,
  fieldConfig: state.schedules.reportScheduleFieldConfig,
  taskItemsitems: state.tasks.recurrableTaskItems,
  items: state.schedules.items,
  editable: state.schedules.showCreateModel,
  allEmployees: state.employees.allEmployees
});

const mapDispatchToProps = dispatch => ({
  createSchedule: scheduleActions.createSchedule,
  updateSchedule: scheduleActions.updateSchedule,
  getReportSchedules: scheduleActions.getSchedules,
  enableSchedule: scheduleActions.enableSchedule,
  removeSchedule: scheduleActions.removeSchedule,
  getAllRecurrableTasks: taskActions.getAllRecurrableTasks
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(CreateReportConfigSidebar);

